
import React, { useEffect } from 'react';
import { Row, Col, Popover, OverlayTrigger, Button, Nav, NavDropdown, Spinner } from 'react-bootstrap';
import { types, helpers } from 'utilities';

import {
  stopProduct,
  startProduct,
  pauseProduct,
  dotsIcon,
  closeBtn,
} from './../../assets/images';




const ProductWindowMenuControls: React.FC<types.IProductWindowMenuControls> = ({ 
  product, isRunning, isLoading, isOverlayVisible,
  handleProductPause, handleProductStop, handleProductStart, handleProductClose, 
  handleShowProductTerminateModal, handleShowProductPresignedLinkModal, handleShowProductUserShareModal,
  handleToggleOverlayVisible 
}) => {

  helpers.logToOutput('ViewportMenu : prod: ', product)
  helpers.logToOutput('ViewportMenu : prod: ', product)
  const hasAndroid = product?.['product-code']?.includes('android') ?? false;
  

  useEffect(() => {
    
  }, [product]);
  
  // helpers.logToOutput('ViewportMenu : url: ', url)
  // helpers.logToOutput('ViewportMenu : prodIndex: ', vpProductIndex)

  if(product && product.id){
    return (
      <>
        <OverlayTrigger show={isOverlayVisible} key={`oTrig-${product.id.toString()}`} placement="left-start"  overlay={
          <Popover id={`popover-positioned-${product.id.toString()}`} className='k-z-ind-99999 k-no-pop-arrow ms-4 k-min-w-200 k-mt-30 k-mr-m-30'>
            <Popover.Header as="h3">
              <Row>
                <Col className='col-10 k-panel-cent-vert'>Control Panel</Col>
                <Col className='col-2 k-panel-cent-vert k-center-content-hor-right'>
                  <img className='k-mh-20 k-opac-50pc k-pointer' onClick={handleToggleOverlayVisible} src={closeBtn}></img>
                </Col>
              </Row>
            </Popover.Header>
            <Popover.Body className='p-0'>
              <Nav>
                <span className='ps-2 ms-2 mb-1 mt-1 txt-10 txt-bold color-lightText'>View</span>

                <hr className='k-border-top-grey w-100-pc mt-1 mb-1'></hr>

                <span className='ps-2 ms-2 mb-1 mt-1 txt-10 txt-bold color-lightText'>Share</span>
                <NavDropdown.Item
                  eventKey="1"
                  style={{opacity: product.state==='running' ? 1 :  0.2, cursor: product.state==='running' ? 'pointer' : 'default'}}
                  className={'p-2 ps-3 ms-2 me-2 txt-12 b-rad-5 k-hover-grey-bg'}
                  onClick={handleShowProductPresignedLinkModal}>
                      Generate share link
                </NavDropdown.Item>
                <NavDropdown.Item eventKey="2" className='p-2 ps-3 ms-2 me-2 mb-2 pt-1 k-hover-grey-bg txt-12 b-rad-5' onClick={handleShowProductUserShareModal}>
                  Share to a user
                </NavDropdown.Item>

                <hr className='k-border-top-grey w-100-pc mt-1 mb-1'/>

                <NavDropdown.Item eventKey="3" className='p-2 ps-3 ms-2 me-2 mb-0 k-hover-grey-bg txt-12 b-rad-5'>
                  <Row>
                    <Col className='col-8 k-panel-cent-vert'>
                        Device Control 
                    </Col>
                    <Col className='col-4'>
                      { isLoading ? 
                        (<div className='k-center-content-hor-right'>
                          <Spinner animation="border" role="status" size="sm">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        </div>)
                        :
                        (<div className='k-center-content-hor-right k-panel-cent-vert'>
                          <img style={{opacity: isRunning ? 0.2: 1 , cursor: isRunning ? 'default' : 'pointer'}} className='k-mh-20 pt-1 ps-1 pe-1 k-pointer-item' src={startProduct} onClick={handleProductStart}></img>
                          <img style={{opacity: !isRunning || hasAndroid ? 0.2 : 1, cursor: !isRunning || hasAndroid ? 'default' : 'pointer'}} className='k-mh-20 pt-1 ps-1 pe-1 k-pointer-item' src={pauseProduct} onClick={handleProductPause}></img>
                          <img style={{opacity: !isRunning ? 0.2 : 1, cursor: !isRunning ? 'default' : 'pointer'}} className='k-mh-20 pt-1 ps-1 pe-0 k-pointer-item' src={stopProduct} onClick={handleProductStop}></img>
                        </div>)
                      }
                    </Col>
                  </Row>
                </NavDropdown.Item>

                <hr className='k-border-top-grey w-100-pc mt-1 mb-1'></hr>
                <NavDropdown.Item eventKey="4" className='p-2 ps-3 ms-2 me-2 mb-2 k-hover-grey-bg txt-12 b-rad-5' onClick={handleProductClose}>
                  <Row>
                    <Col className='col-8 k-panel-cent-vert'>
                      Close
                    </Col>
                    {/* <Col className='col-4'>
                      {isLoading ? (
                        <div className='k-center-content-hor-right'>
                          <Spinner animation="border" role="status" size="sm">
                            <span className="visually-hidden">Closing...</span>
                          </Spinner>
                        </div>
                      ) : (
                        <></>
                      )}
                    </Col> */}
                  </Row>
                </NavDropdown.Item>

                <NavDropdown.Item eventKey="5" className='p-2 ps-3 ms-2 me-2 mb-2 k-hover-grey-bg txt-12 b-rad-5' onClick={handleShowProductTerminateModal}>
                  <Row>
                    <Col className='col-8 k-panel-cent-vert'>
                      Terminate instance 
                    </Col>
                  </Row>
                </NavDropdown.Item>
              </Nav>
            </Popover.Body>
          </Popover>
        }>
          <Button variant='link' className="k-bg-trans m-0 p-0 k-border-none k-center-content-hor-right k-no-button">
            <img id="showProdDragPopover" className='k-mh-20 pt-1 ps-1 pe-1 k-pointer-item' onClick={handleToggleOverlayVisible} src={dotsIcon}></img>
          </Button>
        </OverlayTrigger>
      </>
    );
  } else {
    return(null)
  }
}

export {
  ProductWindowMenuControls
}