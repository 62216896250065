import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Form, Container, Dropdown, Button } from 'react-bootstrap';

import { 
  expandIcon,
  accountIcon,
  prodAdd
} from './../../assets/images';

import * as utilComponents from './../utilities'

import {
  variables,
  helpers
} from 'utilities';



const AdminGroupsContentEditFormPane: React.FunctionComponent<any> = (props: any) => {
  const [editFormBody, setEditFormBody] = useState<any>({});
  const [isGroupNameValid, setGroupNameValid] = useState(true);
  const [isGroupDescriptionValid, setGroupDescriptionValid] = useState(true);
  const [groupUsers, setGroupUsers] = useState<any>([]);
  
  const [isFormValid, setFormValid] = useState(false);
  const [isFormChanged, setFormChanged] = useState(false);
  
  const { 
    groupContent, 
    users, 
    editGroupSubmitError,
    handleGroupEditSubmit
  } = props;

  useEffect(() => {
    setFormChanged(false);
    setEditFormBody({...groupContent});
    setGroupNameValid(true);
    setGroupDescriptionValid(true);
    setGroupUsers((groupContent && groupContent.users) || []);
  }, [groupContent]);

  const handleInputChange = (event: any) => {
    // Toggle Form has Changed
    setFormChanged(true);

    //
    if(event.target.name === 'name') {
      // event.target.value = event.target.value.split(" ").join("");
      event.target.value = event.target.value.replace(' ', '');
      if (variables.DEFAULT_FORM_VALIDATION.GROUP.NAME.test(event.target.value)) {
        setGroupNameValid(true);
      } else {
        setGroupNameValid(false);
      }
    }

    if(event.target.name === 'description') {
      // event.target.value = event.target.value.split(" ").join("");
      // event.target.value = event.target.value.replace(" ", "");
      if (variables.DEFAULT_FORM_VALIDATION.GROUP.DESCRIPTION.test(event.target.value) || event.target.value === '') {
        setGroupDescriptionValid(true);
      } else {
        setGroupDescriptionValid(false);
      }
    }


    setEditFormBody({
      ...editFormBody,
      [event.target.name]: event.target.value,
    });


    if (isGroupNameValid && isGroupDescriptionValid ){
      setFormValid(true)
    } else {
      setFormValid(false)
    }

  }


  const addGroupUser = (event: any, userId: any) => {
    event.preventDefault();

    setGroupUsers([
      ...groupUsers,
      userId
    ]);

    setFormChanged(true);
  }

  const removeGroupUser = (event: any, userId: number) => {
    event.preventDefault();
      
    setGroupUsers([...groupUsers.filter((user: any) => user !== userId)]);
    
    setFormChanged(true);
  }


  const validateForm = () => {
    return;
  }

  const handleFormSubmit = async(event: any) => {
    event.preventDefault();

    validateForm();

    if (isGroupNameValid && isGroupDescriptionValid){

      await handleGroupEditSubmit({body: {
        ...editFormBody,
        users: [
          ...groupUsers
        ]
      }});

    } 
  }


  return (
    <>
   
      <Form onSubmit={handleFormSubmit} key={groupContent.id} className='m-2'>
        <Container className="p-0">
          <Row className="m-0">
            <Col className="col-12">
              <Row className="m-0">
                <Col className="col-4 ps-0">
                  <Row className="m-0 k-inputText15">
                      Name
                  </Row>
                  <Row className="m-0">
                    <Form.Group className="p-0 " controlId="paneGroupEditForm.Name">
                      <Form.Control className="p-0 k-inputText k-inputText15-light" type="text" defaultValue={groupContent.name} name='name' onChange={handleInputChange}/>
                    </Form.Group>
                  </Row>
                </Col>
                <Col className="col-8">
                  <Row>
                      Description
                  </Row>
                  <Row>
                    <Form.Group className="p-0 " controlId="paneGroupEditForm.Description">
                      <Form.Control className="p-0 k-inputText k-inputText15" type="text" defaultValue={groupContent.description}  name='description' onChange={handleInputChange} />
                    </Form.Group>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col className="col-11 p-0">
                  {isGroupNameValid ?  null: <small style={{color: 'red'}}>Enter a valid Group Name. {'(5 - 25 Alphanumeric only)'}</small>}
                  {isGroupDescriptionValid ?  null : <small style={{color: 'red'}}>Enter a valid Group Description. {'(Alphabets, Numbers, space and charcaters[-., ()/] accepted)'}</small>}
                </Col>
              </Row>
            </Col>
          </Row>

          

          <Row className="m-0">
            <Col className="col-12 pb-3">
              <Row className="m-0">
                <Col className="col-12 ps-0">
                  <Row className="m-0 p-0">
                    <Row className="m-0 p-0">
                      <Col className="col-4 ps-0 k-inputText15">
                            Username
                      </Col>
                      <Col className="col-5 ps-1 k-inputText15">
                            Date Added
                      </Col>
                      <Col className="col-3 k-inputText15">
                          Action
                      </Col>
                    </Row>

                    {groupUsers && groupUsers.map((user: any, index: number) => (
                      <Row className="m-0 p-0" key={`paneGroupEditForm.Users.${index}`}>
                        <Col className="col-4 k-inputText k-inputText15-light ps-0">
                          {user}
                        </Col>
                        <Col className="col-5 k-inputText k-inputText15-light ps-1">
                          ---
                        </Col>
                        <Col className="col-3">
                          <div style={{ cursor: 'pointer'}} onClick={(event: any) => removeGroupUser(event, user)}>
                            <span>
                              remove
                              {/*<img className="k-ctrlImg" src={productMediaDeleteIcon} alt={"Delete Icon"}/>*/}
                            </span>
                          </div>
                        </Col>
                      </Row>))}
                  </Row>

                  <Row>
                    <Col className="col-11 p-0 ">
                      <Dropdown>
                        <Dropdown.Toggle as={utilComponents.AdminGroupAddUsersDropdownToggle}>
                          <Row className='m-0 pt-1'>
                            <Col className="col-1 pe-0">
                              <img alt="name icon" src={accountIcon} className="pe-1 k-mmh-15 k-pb-2 k-ctrlImg"/>
                            </Col>
                            <Col className="col-11 p-0 k-inputText15 k-ml--10 k-pt-1">
                              Add user
                            </Col>
                          </Row>
                        </Dropdown.Toggle>
                        <Dropdown.Menu as={utilComponents.AdminGroupAddUsersDropdownMenu} >
                          {users && users.map((user: any, index: number) => {
                            return (groupUsers && groupUsers.includes(user.id)) ?   
                              null :
                              (<Dropdown.Item className="k-inputText12" key={`paneGroupEditForm.Dropdown.${index}`} onClick={(event: any) => addGroupUser(event, user.id)}>{user.id}</Dropdown.Item>)
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                        
                    </Col>
                  </Row>
                      
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="m-0 pb-3">
            <Row className="pe-0">
              <Col className="px-0 col-9 col-auto"></Col>
              <Col className="col p-0 ">
                <div className="pt-2">
                  <Button variant="success" 
                    type="submit"
                    disabled={!isGroupNameValid || !isGroupDescriptionValid || !isFormChanged }
                    size="sm" className="k-btn">
                      Update
                  </Button>
                </div>
              </Col>
            </Row>
            {editGroupSubmitError? (<>
              <small style={{color: 'red'}}>{editGroupSubmitError}</small>
            </>): null}
          </Row>
        </Container>
      </Form>

    </>
  );
}

const AdminUsersContentEditFormPane: React.FunctionComponent<any> = (props: any) => {
  const [editFormBody, setEditFormBody] = useState<any>({});
  const [isDisplayNameValid, setDisplayNameValid] = useState(true);
  const [isRoleValid, setRoleValid] = useState(true);
  const [userGroupIds, setUserGroupIds] = useState<any>([]);
  const [securityGroups, setSecurityGroups] = useState<any>([]);

  const [isPhoneValid, setPhoneValid] = useState(true);
  const [isNameFieldsValid, setNameFieldsValid] = useState(true);
  const [isEmailValid, setEmailValid] = useState(true);
  
  const [isFormChanged, setFormChanged] = useState(false);
  
  const { 
    userContent, 
    groups, 
    editUserSubmitError,
    handleUserEditSubmit,
    mediaControlHandlers
  } = props;

  useEffect(() => {
    setFormChanged(false);
    setEditFormBody({...userContent});
    setDisplayNameValid(true);
    setRoleValid(true);
    // setGroupDescriptionValid(true);
    setSecurityGroups(groups)
    setUserGroupIds((userContent && userContent.security) || []);
  }, [userContent.id]);

  const handleInputChange = (event: any) => {
    // Toggle Form has Changed
    setFormChanged(true);

    if(variables.DEFAULT_FORM_NAME_FIELDS.includes(event.target.name)) {
      // event.target.value = event.target.value.split(" ").join("");
      //event.target.value = event.target.value.replace(' ', '');
      if (variables.DEFAULT_FORM_VALIDATION.USER.NAME.test(event.target.value) || event.target.value === '') {
        setNameFieldsValid(true);
      } else {
        setNameFieldsValid(false);
      }
    }
    
    if(event.target.name === 'email') {
      // event.target.value = event.target.value.split(" ").join("");
      event.target.value = event.target.value.replace(' ', '');
      if (variables.DEFAULT_FORM_VALIDATION.USER.EMAIL.test(event.target.value)) {
        setEmailValid(true);
      } else {
        setEmailValid(false);
      }
    }

    if(event.target.name === 'display_name') {
      // event.target.value = event.target.value.split(" ").join("");
      //event.target.value = event.target.value.replace(' ', '');
      if (variables.DEFAULT_FORM_VALIDATION.USER.DISPLAY_NAME.test(event.target.value) || event.target.value === '') {
        setDisplayNameValid(true);
      } else {
        setDisplayNameValid(false);
      }
    }

    if(event.target.name === 'phone') {
      // event.target.value = event.target.value.split(" ").join("");
      event.target.value = event.target.value.replace(' ', '');
      if (variables.DEFAULT_FORM_VALIDATION.USER.PHONE_NUMBER.test(event.target.value) || event.target.value === '') {
        setPhoneValid(true);
      } else {
        setPhoneValid(false);
      }
    }

    if(event.target.name === 'role') {
      if ( event.target.value !== '') {
        setRoleValid(true);
      } else {
        setRoleValid(false);
      }
    }


    setEditFormBody({
      ...editFormBody,
      [event.target.name]: event.target.value,
    });

  }

  const addUserGroup = (event: any, groupId: any) => {
    event.preventDefault();

    setUserGroupIds([
      ...userGroupIds,
      groupId
    ]);

    setFormChanged(true);
  }

  const removeUserGroup = (event: any, groupId: number) => {
    event.preventDefault();
      
    setUserGroupIds([...userGroupIds.filter((group: any) => group !== groupId)]);
    
    setFormChanged(true);
  }

  const validateForm = () => {
    return;
  }

  const handleFormSubmit = async(event: any) => {
    event.preventDefault();

    validateForm();

    if (isDisplayNameValid && isEmailValid && isRoleValid ){
      const submitBody = {
        id: userContent.id,
        name: {
          last: editFormBody['lastName'] || userContent['name']['last'] || '',
          first: editFormBody['firstName'] || userContent['name']['first'] || '',
          middle: editFormBody['middleName'] || userContent['name']['middle'] || '',
        },
        display_name: editFormBody['display_name'] || userContent['display_name'],
        email: editFormBody['email']?.toLowerCase() || userContent['email'],
        phone: editFormBody['phone'] || userContent['phone'],
        role: editFormBody['role'] || userContent['role'],
        profile_photo: {
          avatar: 'default' // Update when photo func available to always keep user's photo
        },
      }

      await handleUserEditSubmit({
        body: {
          ...submitBody
        }
      });

      setFormChanged(false)

    } 
  }

  const editObject = {
    'id': '6bb2de5c-c5e4-47f6-8909-85848f720156',
    'name': {
      'last': 'wick',
      'first': 'john',
      'middle': ''
    },
    'display_name': 'jwick',
    'email': 'me@kuro.black',
    'phone': '',
    'profile_photo': {
      'custom_file_name': 'profile_photo.png',
      'custom_photo': true
    },
    'role': 'administrator'
  }


  //Edit fields: Name, Display Name
  //Edit fields: Role, Status, Last Sign-In
  //Non Edit: email, uuid, 


  return (
    <>
      <Form onSubmit={handleFormSubmit} key={userContent.id} className='m-2'>
        <Container className="p-0 k-hmh-400px k-overflow-y-scroll">
          
          <Row className="m-0">
            <Col className="col-12 pb-3">
              <Row className="m-0">
                <Col className="col-3 ps-0">
                  <span className='txt-10 color-lightHeader'>Full Name</span>
                  <Form.Group className="p-0 " controlId="paneGroupEditForm.Firstname">
                    <Form.Control className="p-0 k-inputText k-inputText15 k-bord-col-trans k-outline-none" type="text" placeholder="First Name" defaultValue={userContent['name']['first']} name='firstName'  onChange={handleInputChange}/>
                  </Form.Group>
                </Col>
                <Col className="col-3 ps-0">
                  <span className='txt-10 color-lightHeader'>Display Name</span>
                  <Form.Group className="p-0 " controlId="paneGroupEditForm.Firstname">
                    <Form.Control className="p-0 k-inputText k-inputText15 k-bord-col-trans k-outline-none" type="text" placeholder="Display Name" defaultValue={userContent['display_name']} name='display_name'  onChange={handleInputChange}/>
                  </Form.Group>
                </Col>
                <Col className="col-4 ps-0">
                  <span className='txt-10 color-lightHeader'>eMail</span>
                  <Form.Group className="p-0 " controlId="paneGroupEditForm.Firstname">
                    <Form.Control disabled className="p-0 k-inputText k-inputText15 k-bord-col-trans k-outline-none" type="text" placeholder="" defaultValue={userContent['email']} name='email'/>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="m-0">
                <Col className="col-11 p-0">
                  {isNameFieldsValid ?  null: <small style={{color: 'red'}}>Enter a valid User Name(s), lowercase {'(1 - 25 Alphanumeric only)'}</small>}
                  {isDisplayNameValid ?  null: <small style={{color: 'red'}}>Enter a valid Display Name. {'(1 - 25 Alphanumeric only)'}</small>}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="m-0">
            <Col className="col-12 pb-3">
              <Row className="m-0">
                <Col className="col-3 ps-0">
                  <span className='txt-10 color-lightHeader'>Role</span>
                  <Form.Group className="p-0 k-bord-col-trans k-outline-none" controlId="paneUserEditForm.Role">
                    <Form.Select aria-label="Select a role" className="p-0 k-inputSelect k-inputText15-light k-bord-col-trans k-outline-none" name='role' defaultValue={userContent.role} onChange={handleInputChange} >
                      <option value="user" >User</option>
                      <option value="administrator">Admin</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col className="col-3 ps-0">
                  <span className='txt-10 color-lightHeader'>Status</span>
                  <div className="p-0 k-inputText k-inputText15-light">
                    { userContent.enabled ? 'ENABLED' : 'DISABLED' }
                  </div>
                </Col>
                {/*<Col className="col-4 ps-0">
                  <span className='txt-10 color-lightHeader'>Password</span>
                  <Form.Group className="p-0 " controlId="paneGroupEditForm.Password">
                    <Form.Control type="password" className="p-0 k-inputText k-inputText15 k-bord-col-trans k-outline-none" placeholder="change password" defaultValue="" name='password'/>
                  </Form.Group>
                </Col>*/}
              </Row>
            </Col>
          </Row>

          <Row className="m-0 pb-3">
            <Row className="pe-0">
              <Col className="col-4 p-0 ps-2">
                <div className="pt-2">
                  <Button variant="success" 
                    type="submit"
                    disabled={ !isDisplayNameValid || !isRoleValid || !isEmailValid || !isFormChanged}
                    size="sm" className="k-btn">
                    Update
                  </Button>
                  <Button variant="danger" 
                    style={{marginLeft: '1%'}}
                    type="button"
                    size="sm" className="k-btn"
                    onClick={(event: any)=>{ mediaControlHandlers.handleUsersListDelete(event, {id: userContent.id, name: userContent['name']['first'] || userContent['display_name'] })}}
                  >
                    Remove
                  </Button>
                </div>
              </Col>
            </Row>
            {editUserSubmitError? (<>
              <small style={{color: 'red'}}>{editUserSubmitError}</small>
            </>): null}
          </Row>

        </Container>
      </Form>
    </>
  );
}

export const AdminUsersContentTab: React.FunctionComponent<any> = (props:any) => {
  const { 
    users,
    groups,
    selectedUser, 
    mediaControlHandlers,
    editUserSubmitError,
    handleUserEditSubmit,
    handleShowAddUserModal, 
    handleAdminSelectObject 
  } = props;
  

  return (
    <>
      <Row className=''>
        <Col className='col-12'>
          <Row className='m-0'>
            <Col lg={6} className="">
              <span>Manage product access for all the users in your organization</span>
            </Col>
          </Row>
          <Row className='m-0 pt-2 mb-4'>
            <Col lg={6} className="text-end">
              <Button className="k-h-30 k-btn-submit-col txt-14 k-center-content" onClick={handleShowAddUserModal} type="submit">Add User</Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
      
        <Col className="col-3 p-0 ">
          <Row className="m-0 mt-2">
            <Col className="col-12">
              <div className="">
                <Row className='m-0 ps-2'>
                  <Table className='k-brd-transparent'>
                    <thead className=''>
                      <tr>
                        {/*<th>ID</th>*/}
                        <th className='pb-1 ps-1 txt-12 color-lightText'>Users</th>
                        {/*<th className='pb-1 txt-12 color-lightText'>Display Name</th>*/}
                        {/*<th>MFA</th>*/}
                        {/*<th className='pb-1 txt-12 color-lightText'>Remove</th>*/}
                      </tr>
                    </thead>
                    <div className="k-hmh-400px k-overflow-y-scroll">
                      <tbody className=''>
                        {users && users.map((user: any, index: number) => {
                          return (
                            <tr className='k-bord-col-trans' key={`admin-users-${user.id}`}>
                              {/*<td 
                                onClick={(event)=> handleAdminSelectObject(event, {type: 'USER', id: user.id})}
                                style={{ cursor: 'pointer'}}
                              >
                                {user.id.split('-')[4]}
                              </td>*/}
                              <td className='pt-1 pb-1' onClick={(event)=> handleAdminSelectObject(event, {type: 'USER', id: user.id})} >
                                <div className={(selectedUser?.content?.id === user.id) ? 'k-bg-selected-light b-rad-5 p-2' : 'k-hover p-2 b-rad-5'}>
                                  <Row><Col className='col-12'>{user.name.first}</Col></Row>
                                  <Row><Col className='col-12 txt-12 color-lightText'>{user.email}</Col></Row>
                                </div>
                              </td>
                              {/*<td onClick={(event)=> handleAdminSelectObject(event, {type: 'USER', id: user.id})}>{user.display_name}</td>*/}
                              {/*<td>Yes</td>*/}
                              {/*<td>
                                <Row>
                                  
                                  <Col md={1}></Col>
                                  <Col md={1}>
                                    <div style={{ cursor: 'pointer'}} onClick={(event: any)=>{ mediaControlHandlers.handleUsersListDelete(event, {id: user.id, name: user.id})}}>
                                      <span>
                                        <img className="k-ctrlImg" src={expandIcon} alt={'Delete Icon'}/>
                                      </span>
                                    </div>
                                  </Col>
                                </Row>
                            </td>*/}
                            </tr>
                          )
                        })}
                      </tbody>
                    </div>
                  </Table>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>

        <Col className="col-9 p-0">
          <Row className="m-0 mt-2">
            <Col className="col-12">
              {(selectedUser && selectedUser.type === 'USER') ? (
                <>
                  <div className="minh-400">
                    <Row className='m-0 k-pt-8'>
                      <Col className='col-12'>
                        <span className='txt-12 color-lightHeader'>User Profile</span>
                        <hr className='k-border-top-black mt-1 mb-1'></hr>
                      </Col>
                    </Row>
                    <AdminUsersContentEditFormPane
                      userContent={selectedUser.content}
                      groups={groups}
                      editUserSubmitError={editUserSubmitError}
                      handleUserEditSubmit={handleUserEditSubmit}
                      mediaControlHandlers={mediaControlHandlers}
                    />
                  </div> 
                </>
              )
                :
                (<>
                  <div className="minh-400">
                    <Row className='m-0 pt-2'>
                      <Col className='col-12'>
                        <span className='txt-18'></span>
                      </Col>
                    </Row>
                  </div>
                </>
                )}
            </Col>
          </Row>
        </Col>

      </Row>
    </>

  );
}

export const AdminGroupsContentTab: React.FunctionComponent<any> = (props:any) => {
  const { 
    groups,
    users, 
    selectedGroup, 
    mediaControlHandlers,
    editGroupSubmitError,
    handleGroupEditSubmit,
    handleShowAddGroupModal, 
    handleAdminSelectObject 
  } = props;


  return (
    <>

      <Row className=''>
        <Col className='col-12'>
          <Row className='m-0'>
            <Col lg={10} className="">
              <h5>Groups</h5>
            </Col>
            <Col lg={2} className="text-end">
              <Button className="k-h-30 k-btn-submit-col txt-14 k-center-content" onClick={handleShowAddGroupModal} type="submit">Add Group</Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row >
        <Col className="col-6 p-0">
          <Row className="m-0 mt-2">
            <Col className="col-12">
              <div className="minh-400-mh-100vh">
                <Row className='k-of-y-scroll m-0 ps-2'>
                  <Table className='k-brd-transparent'>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th colSpan={2}>Description</th>
                        <th colSpan={2}>Created On</th>
                        <th>Remove</th>
                      </tr>
                    </thead>
                    <tbody className='k-t-body-scroll-admin'>
                      {groups && groups.map((group: any, index: number) => {
                        return (
                          <tr key={`admin-groups-${index}`}>
                            <td 
                              onClick={(event)=> handleAdminSelectObject(event, {type: 'GROUP', id: group.id})}
                              style={{ cursor: 'pointer'}}
                            >
                              {group.name}
                            </td>
                            <td colSpan={2}>{group.description}</td>
                            <td colSpan={2}>---</td>
                            <td> 
                              <Row>
                                
                                <Col md={1}></Col>
                                <Col md={1}>
                                  <div style={{ cursor: 'pointer'}} onClick={(event: any)=>{ mediaControlHandlers.handleGroupsListDelete(event, {id: group.id, name: group.name})}}>
                                    <span>
                                      <img className="k-ctrlImg" src={expandIcon} alt={'Delete Icon'}/>
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                        )
                      })}                    
                    </tbody>
                  </Table>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>

        <Col className="col-6 p-0">
          <Row className="m-0 mt-2">
            <Col className="col-12">
              {(selectedGroup && selectedGroup.type === 'GROUP') ? (
                <>
                  <div  className="card shadow minh-400">
                    <Row className='m-0 pt-2'>
                      <Col className='col-12'>
                        <h5>Group Detail</h5>
                      </Col>
                    </Row>
                    <AdminGroupsContentEditFormPane
                      groupContent={selectedGroup.content}
                      users={users}
                      editGroupSubmitError={editGroupSubmitError}
                      handleGroupEditSubmit={handleGroupEditSubmit}
                    />

                  </div>  
                </>)
                :
                (<>
                  <div  className="card shadow minh-400">
                    <Row className='m-0 pt-2'>
                      <Col className='col-12'>
                        <h5>Select Group</h5>
                      </Col>
                    </Row>
                  </div>
                </>)
              }
            </Col>
          </Row>
        </Col>
      </Row>
    </>

  );
}

const AdminUsersContentEditFormPaneBackup: React.FunctionComponent<any> = (props: any) => {
  const [editFormBody, setEditFormBody] = useState<any>({});
  const [isDisplayNameValid, setDisplayNameValid] = useState(true);
  const [isRoleValid, setRoleValid] = useState(true);
  const [userGroupIds, setUserGroupIds] = useState<any>([]);
  const [securityGroups, setSecurityGroups] = useState<any>([]);

  const [isPhoneValid, setPhoneValid] = useState(true);
  const [isNameFieldsValid, setNameFieldsValid] = useState(true);
  const [isEmailValid, setEmailValid] = useState(true);
  
  const [isFormChanged, setFormChanged] = useState(false);
  
  const { 
    userContent, 
    groups, 
    editUserSubmitError,
    handleUserEditSubmit
  } = props;

  useEffect(() => {
    setFormChanged(false);
    setEditFormBody({...userContent});
    setDisplayNameValid(true);
    setRoleValid(true);
    // setGroupDescriptionValid(true);
    setSecurityGroups(groups)
    setUserGroupIds((userContent && userContent.security) || []);
  }, [userContent.id]);

  const handleInputChange = (event: any) => {
    // Toggle Form has Changed
    setFormChanged(true);

    if(variables.DEFAULT_FORM_NAME_FIELDS.includes(event.target.name)) {
      // event.target.value = event.target.value.split(" ").join("");
      event.target.value = event.target.value.replace(' ', '');
      if (variables.DEFAULT_FORM_VALIDATION.USER.NAME.test(event.target.value) || event.target.value === '') {
        setNameFieldsValid(true);
      } else {
        setNameFieldsValid(false);
      }
    }
    
    if(event.target.name === 'email') {
      // event.target.value = event.target.value.split(" ").join("");
      event.target.value = event.target.value.replace(' ', '');
      if (variables.DEFAULT_FORM_VALIDATION.USER.EMAIL.test(event.target.value)) {
        setEmailValid(true);
      } else {
        setEmailValid(false);
      }
    }

    if(event.target.name === 'displayName') {
      // event.target.value = event.target.value.split(" ").join("");
      event.target.value = event.target.value.replace(' ', '');
      if (variables.DEFAULT_FORM_VALIDATION.USER.DISPLAY_NAME.test(event.target.value) || event.target.value === '') {
        setDisplayNameValid(true);
      } else {
        setDisplayNameValid(false);
      }
    }

    if(event.target.name === 'phone') {
      // event.target.value = event.target.value.split(" ").join("");
      event.target.value = event.target.value.replace(' ', '');
      if (variables.DEFAULT_FORM_VALIDATION.USER.PHONE_NUMBER.test(event.target.value) || event.target.value === '') {
        setPhoneValid(true);
      } else {
        setPhoneValid(false);
      }
    }

    if(event.target.name === 'role') {
      if ( event.target.value !== '') {
        setRoleValid(true);
      } else {
        setRoleValid(false);
      }
    }


    setEditFormBody({
      ...editFormBody,
      [event.target.name]: event.target.value,
    });

  }

  const addUserGroup = (event: any, groupId: any) => {
    event.preventDefault();

    setUserGroupIds([
      ...userGroupIds,
      groupId
    ]);

    setFormChanged(true);
  }

  const removeUserGroup = (event: any, groupId: number) => {
    event.preventDefault();
      
    setUserGroupIds([...userGroupIds.filter((group: any) => group !== groupId)]);
    
    setFormChanged(true);
  }

  const validateForm = () => {
    return;
  }

  const handleFormSubmit = async(event: any) => {
    event.preventDefault();

    validateForm();

    if (isDisplayNameValid && isEmailValid && isRoleValid ){

      await handleUserEditSubmit({
        body: {
          id: userContent.id,
          name: {
            last: editFormBody['lastName'] || userContent['name']['last'] || '',
            first: editFormBody['firstName'] || userContent['name']['first'] || '',
            middle: editFormBody['middleName'] || userContent['name']['middle'] || '',
          },
          display_name: editFormBody['display_name'] || userContent['display_name'],
          email: editFormBody['email'] || userContent['email'],
          phone: editFormBody['phone'] || userContent['phone'],
          role: editFormBody['role'] || userContent['role'],
          profile_photo: {
            avatar: 'default' // Update when photo func available to always keep user's photo
          },
          // profile_photo: {
          //   custom_file_name: "profile_photo.png",
          //   custom_photo: true
          // },
          // security: [
          //   ...userGroupIds
          // ]
        }
      });

    } 
  }

  const editObject = {
    'id': '6bb2de5c-c5e4-47f6-8909-85848f720156',
    'name': {
      'last': 'wick',
      'first': 'john',
      'middle': ''
    },
    'display_name': 'jwick',
    'email': 'me@kuro.black',
    'phone': '',
    'profile_photo': {
      'custom_file_name': 'profile_photo.png',
      'custom_photo': true
    },
    'role': 'administrator'
  }


  return (
    <>
      <Form onSubmit={handleFormSubmit} key={userContent.id} className='m-2'>
        <Container className="p-0">
          <Row className="m-0">
            <Col className="col-12 pb-3">
              <Row className="m-0 k-inputText15">
                UID :
                <div className="p-0 k-inputText k-inputText15-light">
                  {userContent.id}
                </div>
              </Row>
            </Col>
          </Row>

          <Row className="m-0">
            <Col className="col-12 pb-3">
              <Row className="m-0">
                Name
              </Row>
              <Row className="m-0">
                <Col className="col-4 ps-0">
                  <Row className="k-inputTextLarge">
                    <Col className="px-0 col-1"></Col>
                    <Col className="col-11 ps-0 ">
                      <Form.Group className="p-0 " controlId="paneGroupEditForm.Firstname">
                        <Form.Control className="p-0 k-inputText k-inputText15" type="text" placeholder="First Name" defaultValue={userContent['name']['first']} name='firstName'  onChange={handleInputChange}/>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col className="col-4 ps-0">
                  <Row className="k-inputTextLarge">
                    <Col className="px-0 col-1"></Col>
                    <Col className="col-11 ps-0 ">
                      <Form.Group className="p-0 " controlId="paneGroupEditForm.Middlename">
                        <Form.Control className="p-0 k-inputText k-inputText15" type="text" placeholder="Middle Name" defaultValue={userContent['name']['middle']} name='middleName'  onChange={handleInputChange}/>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col className="col-4 ps-0">
                  <Row className="k-inputTextLarge">
                    <Col className="px-0 col-1"></Col>
                    <Col className="col-11 ps-0 ">
                      <Form.Group className="p-0 " controlId="paneGroupEditForm.Lastname">
                        <Form.Control className="p-0 k-inputText k-inputText15" type="text" placeholder="Last Name" defaultValue={userContent['name']['last']} name='lastName'  onChange={handleInputChange}/>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="m-0">
                <Col className="col-11 p-0">
                  {isNameFieldsValid ?  null: <small style={{color: 'red'}}>Enter a valid User Name(s), lowercase {'(5 - 25 Alphanumeric only)'}</small>}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="m-0">
            <Col className="col-12 pb-3">
              <Row className="m-0">
                Display name and Contact
              </Row>
              <Row className="m-0">
                <Col className="col-4 ps-0">
                  <Row className="k-inputTextLarge">
                    <Col className="px-0 col-1"></Col>
                    <Col className="col-11 ps-0 ">
                      <Form.Group className="p-0 " controlId="paneGroupEditForm.DisplayName">
                        <Form.Control className="p-0 k-inputText k-inputText15" type="text" placeholder="Display Name" defaultValue={userContent['display_name']} name='displayName'  onChange={handleInputChange}/>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col className="col-4 ps-0">
                  <Row className="k-inputTextLarge">
                    <Col className="px-0 col-1"></Col>
                    <Col className="col-11 ps-0 ">
                      <Form.Group className="p-0 " controlId="paneGroupEditForm.Email">
                        <Form.Control className="p-0 k-inputText k-inputText15" type="text" placeholder="Email" defaultValue={userContent['email']} name='email'  onChange={handleInputChange}/>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col className="col-4 ps-0">
                  <Row className="k-inputTextLarge">
                    <Col className="px-0 col-1"></Col>
                    <Col className="col-11 ps-0 ">
                      <Form.Group className="p-0 " controlId="paneGroupEditForm.Phone">
                        <Form.Control className="p-0 k-inputText k-inputText15" type="text" placeholder="Phone" name='phone' defaultValue={userContent['phone']}  onChange={handleInputChange}/>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="m-0">
                <Col className="col-11 p-0">
                  {isDisplayNameValid ?  null: <small style={{color: 'red'}}>Enter a valid Display Name. {'(5 - 25 Alphanumeric only)'}</small>}
                  {isEmailValid ?  null: <small style={{color: 'red'}}>Enter a valid Email Name.</small>}
                  {isPhoneValid ?  null: <small style={{color: 'red'}}>Enter a Phone </small>}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="m-0">
            <Col className="col-12 pb-3">
              <Row className="m-0">
                Access Control
              </Row>
              <Row className="m-0">
                <Col className="col-4 ps-0">
                  <Row className="k-inputTextLarge">
                    <Col className="px-0 col-1"></Col>
                    <Col className="col-11 ps-0 ">
                      <Form.Group className="p-0 " controlId="paneUserEditForm.Role">
                        <Form.Select aria-label="Select a role" className="p-0 k-inputSelect k-inputText15-light" name='role' defaultValue={userContent.role} onChange={handleInputChange} >
                          <option value="user" >User</option>
                          <option value="administrator">Admin</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col className="col-4 ps-0">
                  <Row className="k-inputTextLarge">
                    <Col className="px-0 col-1"></Col>
                    <Col className="col-11 ps-0 ">
                      User State:
                      <div className="p-0 k-inputText k-inputText15-light">
                        { userContent.enabled ? 'ENABLED' : 'DISABLED' }
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col className="col-4 ps-0">
                  <Row className="k-inputTextLarge">
                    <Col className="px-0 col-1"></Col>
                    <Col className="col-11 ps-0 ">
                      Last Sign
                      <div className="p-0 k-inputText k-inputText15-light">
                        ---
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="m-0">
                <Col className="col-11 p-0">
                  {isRoleValid ?  null: <small style={{color: 'red'}}>Select User's Role</small>}
                </Col>
              </Row>
            </Col>
          </Row>
          
          <Row className="m-0">
            <Col className="col-12">
              <Row className="m-0">
                <Col className="col-12 ps-0">
                  <Row className="m-0 p-0">
                    <Row className="m-0 p-0">
                      <Col className="col-4 ps-0 k-inputText15">
                          Group
                      </Col>
                      <Col className="col-5 ps-1 k-inputText15">
                          Description
                      </Col>
                      <Col className="col-3 k-inputText15">
                          Action
                      </Col>
                    </Row>

                    {userGroupIds && userGroupIds.map((groupId: any, index: number) => (
                      <Row key={`paneUserEditForm.Groups.${index}-${groupId}`} className='m-0 p-0'>
                        <Col className="col-4 k-inputText k-inputText15-light ps-0">
                          {securityGroups.filter((globalGroup: any) => groupId === globalGroup.id)[0].name}
                        </Col>
                        <Col className="col-5 k-inputText k-inputText15-light ps-1">
                        ----
                        </Col>
                        <Col className="col-3">
                          <div style={{ cursor: 'pointer'}} onClick={(event: any) => removeUserGroup(event, groupId)}>
                            <span>
                            remove
                            </span>
                          </div>
                        </Col>
                      </Row>))}
                  </Row>

                  <Row>
                    <Col className="col-11 p-0 ">
                      <Dropdown>
                        <Dropdown.Toggle as={utilComponents.SelectGroupDropdownToggle}>
                          <Row className='m-0 pt-1'>
                            <Col className="col-1 pe-0">
                              <img alt="name icon" src={accountIcon} className="pe-1 k-mmh-15 k-pb-2 k-ctrlImg"/>
                            </Col>
                            <Col className="col-11 p-0 k-inputText15 k-ml--10 k-pt-1">
                              Add group
                            </Col>
                          </Row>
                        </Dropdown.Toggle>
                        <Dropdown.Menu as={utilComponents.SelectGroupDropdownMenu} >
                          {securityGroups && securityGroups.map((group: any, index: number) => {
                            return (userGroupIds && userGroupIds.includes(group.id)) ?   
                              null :
                              (<Dropdown.Item className="k-inputText12" key={`paneUserEditForm.Dropdown.${index}`} onClick={(event: any) => addUserGroup(event, group.id)}>{group.name}</Dropdown.Item>)
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="m-0 pb-3">
            <Row className="pe-0">
              <Col className="px-0 col-9 col-auto"></Col>
              <Col className="col p-0 ">
                <div className="pt-2">
                  <Button variant="success" 
                    type="submit"
                    disabled={ !isDisplayNameValid || !isRoleValid || !isEmailValid || !isFormChanged}
                    size="sm" className="k-btn">
                    Update
                  </Button>
                </div>
              </Col>
            </Row>
            {editUserSubmitError? (<>
              <small style={{color: 'red'}}>{editUserSubmitError}</small>
            </>): null}
          </Row>
        </Container>
      </Form>
    </>
  );
}