import { useDroppable } from '@dnd-kit/core';
import { FC, useEffect, useState } from 'react';

import { helpers, types, variables } from 'utilities'


const handleIframeMouseOver = async(event: any) => {
  event.preventDefault();
  const iframeDocument = document.getElementById(event.target.id);
  iframeDocument?.focus();
}


const DynamicWindow: FC<types.IViewport> = ({ vpProductIndex, isRunning, products, viewportProducts}) => {
  const { setNodeRef } = useDroppable({
    id: `cartdroppable-${vpProductIndex}`
  });
 
  return (
    <div id={`cartdroppable-${vpProductIndex}`} className={'cart k-iFrameHolder'} ref={setNodeRef}>
      { isRunning ? 
        <iframe className="k-iFrame d-flex align-self-stretch" 
          allow="clipboard-read; clipboard-write;camera; microphone; web-share; display-capture;" id={`desktop-iframe-vp-${vpProductIndex}`} 
          src={viewportProducts[vpProductIndex].url} title="BOB"
          onMouseOver={handleIframeMouseOver}>
        </iframe>
        : 
        <>
          <span></span>
        </>
      }
    </div>
  );
};

const FullWindow: FC<types.IViewportFullWindow> = ({ product, isRunning }) => {
  const [url, setUrl] = useState<string | null>(null);
  const { setNodeRef } = useDroppable({
    id: 'cartdroppable-1'
  });

  useEffect(() => {
    if (product && product.id) {
      const pu = helpers.generateProductUrl(product['product-code'], product.id);

      helpers.logToOutput('FULL WINDOW: ', pu)
      setUrl(pu)
    }
  }, [product])

  //<ul className={styles.cart} ref={setNodeRef}>
  {/*props.items.map((item, idx) => (
        <div key={`${item}-${idx}`} className={styles["cart-item"]}>
          {item}
        </div>
      ))*/}

  // const fetchDataWithToken = async (): Promise<void> => {
  //   try {
          
  //     const token = JSON.parse(localStorage.getItem('GUAC_AUTH'));

  //     if (!token) {
  //       helpers.logToOutput('GUAC_AUTH token not found in localStorage');
  //       return;
  //     }
    
  //     const url = `1/api/session/data/mysql/activeConnections`;
    
  //     const data = await fetchActiveConnection(url, 'GET', 'application/json', token.authToken);
  //     helpers.logToOutput('Flash ! ActiveConnections: ', data);
  //     try {
  //       const responseObject = JSON.parse(data);
  //       const keys = Object.keys(responseObject);
  //       helpers.logToOutput('Flash Keys : ', keys)
  //       keys.forEach(key => {
  //         const identifier = responseObject[key].identifier;
  //         helpers.logToOutput(`Flash Identifier value for key ${key}:`, identifier);
  //         //removeConnection(url, 'PATCH', 'application/json', token.authToken, '/'+identifier );
  //       });
  //     } catch (error) {
  //       helpers.logToOutput('Error parsing JSON:', error);
  //     }

    
  //   } catch (error) {
  //     helpers.logToOutput('Flash ActiveConnections ERROR: ', error);
  //     // Handle errors
  //   }
  // };

  // useEffect(() => {
  //   fetchDataWithToken();
  // }, [product]);

  return (
    <div id={'cartdroppable-1'} className={'cart k-iFrameHolder'} ref={setNodeRef}>
      {isRunning ? 
        <iframe className="k-iFrame d-flex align-self-stretch" 
          allow="clipboard-read; clipboard-write;camera; microphone; web-share; display-capture;" id={'desktop-iframe-vp-1'} 
          src={url} title="BOB"
          onMouseOver={handleIframeMouseOver}>
        </iframe>
        :
        <p>Product Is Not Running, Try a refresh</p>
      }
    </div>
  );
};


const fetchActiveConnection = async(url: string, method: string, contentType: string, token: any): Promise<string> => {
  try {
    const response = await fetch(url, {
      method: method,
      headers: {
        'Content-Type': contentType,
        'Guacamole-Token': token
      }
    });

    if (!response.ok) {
      throw new Error('Flash Network response was not ok');
    }

    const data = await response.text(); // Change to response.json() if expecting JSON data
    helpers.logToOutput('flash Response from server:', data);
    return data;
  } catch (error) {
    helpers.logToOutput('Flash There was an error with the request:', error);
    throw error; // Rethrow the error to be caught by the caller
  }
}

const removeConnection = async (url: string, method: string, contentType: string, token: any, path: string): Promise<string> => {
  try {
    const response = await fetch(url, {
      method: method,
      headers: {
        'Content-Type': contentType,
        'Guacamole-Token': token
      },
      body: JSON.stringify([{
        'op': 'remove',
        'path': path
      }]),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.text(); // Change to response.json() if expecting JSON data
    helpers.logToOutput('Response from server:', data);
    return data;
  } catch (error) {
    helpers.logToOutput('There was an error with the request:', error);
    throw error; // Rethrow the error to be caught by the caller
  }
}

export {
  DynamicWindow, 
  FullWindow
};