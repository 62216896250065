import { types, helpers } from 'utilities';
import { 
  DEFAULT_CONFIG, 
  DEFAULT_API_RESULT
} from './../variables';



export const getProducts = async(_: any): Promise<types.IApiHandledResponse> => {
  try {
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + '/products', {
      method: 'GET',
      // body: JSON.stringify(query.body)
    });
    
    if(response.status !== 200 ){
      throw new Error(`GET Products HTTP error! Status: ${response.status}`);
    } else {
      
      const responseJson = await response.json();
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: responseJson
      }
    }
  } catch (error: any) {
    helpers.logToOutput('GET Products Error', error.message); // Setting the error state if there's an error
    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
}

export const getProduct = async(query: any): Promise<types.IApiHandledResponse> => {
  try {
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + `/products/${query.body.id}`, {
      method: 'GET',
    });

    if(response.status !== 200 ){
      throw new Error(`GET Product HTTP error! Status: ${response.status}`);
    } else {
      
      const responseJson = await response.json();
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: responseJson
      }
    }
  } catch (error: any) {
    helpers.logToOutput('GET Product Error', error.message); // Setting the error state if there's an error
    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
}

export const createPaymentIntent = async(query: any) : Promise<types.IApiHandledResponse> => {

  try {
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + '/pay', {
      method: 'POST',
      body: JSON.stringify(query.body)
    });

    if(response.status !== 200 ){
      throw new Error(`POST Payment Intent HTTP error! Status: ${response.status}`);
    } else {
      
      const responseJson = await response.json();
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: responseJson
      }
    }
  } catch (error: any) {
    helpers.logToOutput('POST Payment Intent', error.message); // Setting the error state if there's an error
    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
}

export const createEnterpriseProduct = async(query: any): Promise<types.IApiHandledResponse> => {
  try {
    helpers.logToOutput('Enterprise Create: Sending API Request...')
    helpers.logToOutput('Enterprise Create: Query : ', query)
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + '/products', {
      method: 'PUT',
      body: JSON.stringify(query.body)
    });

    helpers.logToOutput('PUT: Enterprise Product: Response : ', response);

    const responseJson = await response.json();

    if(response.status !== 200 ){
      helpers.logToOutput('PUT: RES: RES: RES', response);
      helpers.logToOutput('PUT: RES: RES: RES', responseJson);

      //throw new Error(`PUT: Enterprise Product: HTTP error! Status: ${response.status}`);

      return {
        status: DEFAULT_API_RESULT.FAILURE,
        body: responseJson
      }

    } else {
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: responseJson
      }
    }
    
  } catch (error: any) {
    helpers.logToOutput('PUT: Enterprise Product: Error', error.message); // Setting the error state if there's an error
    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
}

export const deleteProduct = async(query: any): Promise<types.IApiHandledResponse> => {
  try {
  
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + `/products/${query.body.id}`, {
      method: 'DELETE',
      body: JSON.stringify({})
    });

    if(response.status !== 200 ){
      throw new Error(`DELETE: Product: HTTP error! Status: ${response.status}`);
    } else {
      
      const responseJson = await response.json();
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: responseJson
      }
    }
  } catch (error: any) {
    helpers.logToOutput('DELETE: Product: Error', error.message); // Setting the error state if there's an error
    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
}

export const startProduct = async(query: any): Promise<types.IApiHandledResponse> => {
  try {
  
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + `/products/${query.body.id}/start`, {
      method: 'POST',
      body: JSON.stringify({})
    });

    if(response.status !== 200 ){
      throw new Error(`POST: ProductStart: HTTP error! Status: ${response.status}`);
    } else {
      
      const responseJson = await response.json();
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: responseJson
      }
    }
  } catch (error: any) {
    helpers.logToOutput('POST: ProductStart:', error.message); // Setting the error state if there's an error
    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
}

export const hibernateProduct = async(query: any): Promise<types.IApiHandledResponse> => {
  try {
  
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + `/products/${query.body.id}/hibernate`, {
      method: 'POST',
      body: JSON.stringify({})
    });

    if(response.status !== 200 ){
      throw new Error(`POST: ProductHibernate: HTTP error! Status: ${response.status}`);
    } else {
      helpers.logToOutput('POST: ProductHibernate: Success : ', response);
      const responseJson = await response.json();
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: responseJson
      }
    }
  } catch (error: any) {
    helpers.logToOutput('POST: ProductHibernate: Error', error.message); // Setting the error state if there's an error
    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
}

export const stopProduct = async(query: any): Promise<types.IApiHandledResponse> => {
  try {
  
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + `/products/${query.body.id}/stop`, {
      method: 'POST',
      body: JSON.stringify({})
    });

    if(response.status !== 200 ){
      throw new Error(`POST: ProductStop: HTTP error! Status: ${response.status}`);
    } else {
      
      const responseJson = await response.json();
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: responseJson
      }
    }
  } catch (error: any) {
    helpers.logToOutput('POST: ProductStop: Error', error.message); // Setting the error state if there's an error
    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
}

export const presignProductLink = async(query: any) => {
  
  try {
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + `/products/${query.id}/presign`, {
      method: 'POST',
      body: JSON.stringify(query.body)
    });

    helpers.logToOutput('presignProductLink response:', response);

    if (!response.ok) {
      throw new Error(`presignProductLink HTTP error! Status: ${response.status}`);
    }

    const responseJson = await response.json();
    return responseJson

  } catch (error: any) {
    helpers.logToOutput('presignProductLink Error', error.message); // Setting the error state if there's an error
  }
}

export const getProductPresignedLink = async(query: any): Promise<types.IApiHandledResponse> => {
  try {
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + `/products/${query.id}/presign`, {
      method: 'POST',
      body: JSON.stringify(query.body)
    });

    helpers.logToOutput('presignProductLink response:', response);

    if (response.status !== 200) {
      throw new Error(`presignProductLink HTTP error! Status: ${response.status}`);
    } else {
      
      const responseJson = await response.json();

      helpers.logToOutput('presignProductLink ResponseJson', responseJson);
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: responseJson
      }
    }

  } catch (error: any) {
    helpers.logToOutput('presignProductLink Error', error.message); // Setting the error state if there's an error

    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
  
}

export const getShareProductUser = async(query: any) => {
  
  try {
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + `/products/${query.id}/users`, {
      method: 'GET'
      //body: JSON.stringify(query.body)
    });

    helpers.logToOutput('getShareProductUser response:', response);

    if (!response.ok) {
      throw new Error(`getShareProductUser HTTP error! Status: ${response.status}`);
    }

    const responseJson = await response.json();
    return responseJson

  } catch (error: any) {
    helpers.logToOutput('getShareProductUser Error', error.message); // Setting the error state if there's an error
  }
  
}

export const putShareProductUser = async(query: any) => {
  
  try {
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + `/products/${query.id}/share`, {
      method: 'PUT',
      body: JSON.stringify(query.body)
    });

    helpers.logToOutput('setShareProductUser response:', response);

    if (!response.ok) {
      throw new Error(`setShareProductUser HTTP error! Status: ${response.status}`);
    }

    const responseJson = await response.json();
    return responseJson

  } catch (error: any) {
    helpers.logToOutput('setShareProductUser Error', error.message); // Setting the error state if there's an error
  }
  
}

export const delShareProductUser = async(query: any) => {
  
  try {
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + `/products/${query.id}/share`, {
      method: 'DELETE',
      body: JSON.stringify(query.body)
    });

    helpers.logToOutput('delShareProductUser response:', response);

    if (!response.ok) {
      throw new Error(`delShareProductUser HTTP error! Status: ${response.status}`);
    }

    const responseJson = await response.json();
    helpers.logToOutput('delShareProductUser return', responseJson)
    return responseJson

  } catch (error: any) {
    helpers.logToOutput('delShareProductUser Error', error.message); // Setting the error state if there's an error
    return error
  }
  
}

export const setUserAttributes = async(query: any): Promise<types.IApiHandledResponse> => {
  try {
  
    /**
   * TO-DO:
   * - Check with API docs and update to work with UI
   * - can accept user's name, photo, etc
   */
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + `/users/${query.body.id}`, {
      method: 'POST',
      body: JSON.stringify(query.body)
    });

    if(response.status !== 200 ){
      throw new Error(`POST: ProfileUpdate: HTTP error! Status: ${response.status}`);
    } else {
      
      const responseJson = await response.json();
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: responseJson
      }
    }
  } catch (error: any) {
    helpers.logToOutput('POST: ProfileUpdate: Error', error.message); // Setting the error state if there's an error
    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
}

export const getUserAttributes = async(query: any): Promise<types.IApiHandledResponse> => {
  try {
  
    /**
   * TO-DO:
   * - Check with API docs and update to work with UI
   * - can accept user's name, photo, etc
   */
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + `/users/${query.body.id}`, {
      method: 'GET',
    });

    if(response.status !== 200 ){
      throw new Error(`GET: UserAttributes: HTTP error! Status: ${response.status}`);
    } else {
      
      const responseJson = await response.json();
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: responseJson
      }
    }
  } catch (error: any) {
    helpers.logToOutput('GET: UserAttributes: Error', error.message); // Setting the error state if there's an error
    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
}

export const getProfilePhoto = async(_: any): Promise<types.IApiHandledResponse> => {
  try {
  
    /**
   * TO-DO:
   * - Check with API docs and update to work with UI
   * - can accept user's name, photo, etc
   */
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + '/users/profile/photo', {
      method: 'GET',
      
    });

    if(response.status !== 200 ){
      throw new Error(`GET: ProfilePhoto: HTTP error! Status: ${response.status}`);
    } else {
      
      const responseJson = await response.json();
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: responseJson
      }
    }
  } catch (error: any) {
    helpers.logToOutput('PATCH: ProfileUpdate: Error', error.message); // Setting the error state if there's an error
    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
}

export const setProfilePhoto = async(query: any): Promise<types.IApiHandledResponse> => {
  try {
  
    /**
   * TO-DO:
   * - Check with API docs and update to work with UI
   * - can accept user's name, photo, etc
   */
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + '/users/profile/photo', {
      method: 'PUT',
      body: JSON.stringify(query.contentBinary),
      headers: {
        'Content-Type': query.contentType,
        'Content-Length': query.contentLength,
      },
    });

    if(response.status !== 200 ){
      throw new Error(`PUT: ProfilePhoto: HTTP error! Status: ${response.status}`);
    } else {
      
      const responseJson = await response.json();
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: responseJson
      }
    }
  } catch (error: any) {
    helpers.logToOutput('PATCH: ProfileUpdate: Error', error.message); // Setting the error state if there's an error
    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
}

export const updateNotificationPreferences = async(query: any): Promise<types.IApiHandledResponse> => {
  
  /**
   * TO-DO:
   * - Needs Cognito
   * - can accept a notifications object of booleans
   * - eg {
   *  email: true,
   *  productUpdates: false,
   * -    }
   */
  try {
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + '/notification', {
      method: 'PATCH',
      body: JSON.stringify({
        changes: query.body
      })
    });

    if(response.status !== 200 ){
      throw new Error(`PATCH: NotificationsUpdate: HTTP error! Status: ${response.status}`);
    } else {
      
      const responseJson = await response.json();
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: responseJson
      }
    }
  } catch (error: any) {
    helpers.logToOutput('PATCH: NotificationsUpdate: Error', error.message); // Setting the error state if there's an error
    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
}

export const  getUsers  = async(_: any): Promise<types.IApiHandledResponse> => {
  try {
  
    /**
     * TO-DO:
     * - Check with API docs and update to work with UI
     * - can accept user's name, photo, etc
     */
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + '/users', {
      method: 'GET',
    });

    helpers.logToOutput('API: USERS: GET: ', response)
  
    if(response.status !== 200 ){
      throw new Error(`GET: UserList: HTTP error! Status: ${response.status}`);
    } else {
        
      const responseJson = await response.json();
      helpers.logToOutput('API: USERS: GET[JSON]: ', responseJson)
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: responseJson['items']
      }
    }
  } catch (error: any) {
    helpers.logToOutput('GET: UserList: Error', error.message); // Setting the error state if there's an error
    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
}

export const  createUser  = async(query: any): Promise<types.IApiHandledResponse> => {
  try {
  
    /**
     * TO-DO:
     * - Check with API docs and update to work with UI
     * - can accept user's name, photo, etc
     */
    helpers.logToOutput('API: USERS: POST: BODY: ', query)
    
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + '/users', {
      method: 'POST',
      body: JSON.stringify(query.body)
    });

    helpers.logToOutput('API: USERS: POST: ', response)
  
    if(response.status !== 200 ){
      throw new Error(`POST: User: HTTP error! Status: ${response.status}`);
    } else {
        
      const responseJson = await response.json();
      helpers.logToOutput('API: USERS: POST[JSON]: ', responseJson)
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: responseJson
      }
    }
  } catch (error: any) {
    helpers.logToOutput('POST: User: Error', error.message); // Setting the error state if there's an error
    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
}

export const  editUser  = async(query: any): Promise<types.IApiHandledResponse> => {
  try {
  
    /**
     * TO-DO:
     * - Check with API docs and update to work with UI
     * - can accept user's name, photo, etc
     */
    helpers.logToOutput('API: USERS: EDIT: BODY: ', query)
    
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + `/users/${query.body.id}`, {
      method: 'POST',
      body: JSON.stringify(query.body)
    });

    helpers.logToOutput('API: USERS: EDIT: ', response)
  
    if(response.status !== 200 ){
      throw new Error(`EDIT: User: HTTP error! Status: ${response.status}`);
    } else {
        
      const responseJson = await response.json();
      helpers.logToOutput('API: USERS: EDIT[JSON]: ', responseJson)
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: responseJson
      }
    }
  } catch (error: any) {
    helpers.logToOutput('EDIT: User: Error', error.message); // Setting the error state if there's an error
    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
}

export const  deleteUser  = async(query: any): Promise<types.IApiHandledResponse> => {
  try {
  
    /**
     * TO-DO:
     * - Check with API docs and update to work with UI
     * - can accept user's name, photo, etc
     */
    helpers.logToOutput('API: USERS: DELETE: BODY: ', query)
    
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + `/users/${query.body.id}`, {
      method: 'DELETE',
    });

    helpers.logToOutput('API: USERS: DELETE: ', response)
  
    if(response.status !== 200 ){
      throw new Error(`DELETE: User: HTTP error! Status: ${response.status}`);
    } else {
        
      const responseJson = await response.json();
      helpers.logToOutput('API: USERS: DELETE[JSON]: ', responseJson)
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: responseJson
      }
    }
  } catch (error: any) {
    helpers.logToOutput('DELETE: User: Error', error.message); // Setting the error state if there's an error
    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
}

export const  getUser  = async(query: any): Promise<types.IApiHandledResponse> => {
  try {
  
    /**
     * TO-DO:
     * - Check with API docs and update to work with UI
     * - can accept user's name, photo, etc
     */
    helpers.logToOutput('API: USERS: DELETE: BODY: ', query)
    
    const response = await fetch(DEFAULT_CONFIG['LAMBDA_API_URL'] + `/users/${query.body.id}`, {
      method: 'GET',
    });

    helpers.logToOutput('API: USER: GET: ', response)
  
    if(response.status !== 200 ){
      throw new Error(`GET: User: HTTP error! Status: ${response.status}`);
    } else {
        
      const responseJson = await response.json();
      helpers.logToOutput('API: USER: GET[JSON]: ', responseJson)
      return {
        status: DEFAULT_API_RESULT.SUCCESS,
        body: responseJson
      }
    }
  } catch (error: any) {
    helpers.logToOutput('GET: User: Error', error.message); // Setting the error state if there's an error
    return {
      status: DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
}