import { Amplify, Auth } from 'aws-amplify';
import AWS from 'aws-sdk/global';
import * as variables from './../variables';
import * as helpers from './../helpers';


const userPoolId = variables.DEFAULT_CONFIG['COGNITO_USERPOOL_ID'];
const clientId = variables.DEFAULT_CONFIG['COGNITO_CLIENT_ID'];
const deploymentType = variables.DEFAULT_CONFIG['TYPE'];


Amplify.configure({
  Auth: {
    region: 'eu-west-2', // REQUIRED - Amazon Cognito Region
    userPoolId: userPoolId, // OPTIONAL - Amazon Cognito User Pool ID
    userPoolWebClientId: clientId, // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    
    // (optional) - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,

    // (optional) - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    cookieStorage: {
      // - Cookie domain (only required if cookieStorage is provided)
      // domain: `.${window.location.hostname}`,
      domain: `${window.location.hostname}`,
      // domain: "localhost",
      // (optional) - Cookie expiration in days
      expires: 1,
      // (optional) - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
      sameSite: 'none',
      // (optional) - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      secure: true
    },

    // (optional) - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    // authenticationFlowType: 'USER_PASSWORD_AUTH' // DEFAULT AWS
    authenticationFlowType: 'CUSTOM_AUTH'
  },
});

//Amplify.Logger.LOG_LEVEL = "DEBUG";


let currentUser: any;


export async function signInWithUsername(username: string, password?: string, newPassword?: string, userMfaCode?: string, userMfaSetupCode?: string) {
  return new Promise(async function (resolve, reject) {
    try {

      if(newPassword){
        currentUser = await Auth.completeNewPassword(
          currentUser, // the Cognito User Object
          newPassword, 
        )

        // resolve(currentUser);
        reject({
          code: 'UserRequiresSignout',
        });

      } else if(userMfaSetupCode){
  
        await Auth.verifyTotpToken(
          currentUser, // Return object from Auth.signIn()
          userMfaSetupCode, // Confirmation code
        );

        await Auth.setPreferredMFA(currentUser, 'TOTP');

        reject({
          code: 'UserRequiresSignout',
        });
      } else if(userMfaCode){
       
        currentUser = await Auth.confirmSignIn(
          currentUser, // Return object from Auth.signIn()
          userMfaCode, // Confirmation code
          'SOFTWARE_TOKEN_MFA' // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
        );

        resolve(currentUser);

      } else {

        currentUser = await Auth.signIn(username, password);
        
        if (currentUser && currentUser.challengeName === 'MFA_SETUP') {

          const code = await Auth.setupTOTP(currentUser);

          reject({
            code: 'UserRequiresMfaSetup',
            mfaSetup: {
              qrString: 'otpauth://totp/AWSCognito:'+ username + '?secret=' + code + '&issuer=KURO_APP',
              code: code
            }
          });

        } else if (
          (currentUser && currentUser.challengeName === 'SMS_MFA') ||
          (currentUser && currentUser.challengeName === 'SOFTWARE_TOKEN_MFA')
        ) {

          reject({code: 'UserRequiresMfaCode'});

        } else if (currentUser && currentUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
        
          reject({code: 'UserRequiresNewPassword'});
  
        } else {
  
          resolve(currentUser);
  
        }
      }

    } catch (error) {
      reject(error);
    }}
  )
}

export async function signInWithEmail(setStatusToTrue: any, email: string, password?: string, newPassword?: string, userMfaCode?: string, userMfaSetupCode?: string, ) {
  try {
    const signinResponseUser = await Auth.signIn(email);
    // Start clock timer
    // Start fetch to ping
    if(signinResponseUser){
      const { challengeParam } = signinResponseUser;

      let authVerificationResponse: any;

      const authIntervalPersistence = setInterval(async () => {
        
        if (deploymentType === 'ENTERPRISE') {
          const verifyResponse = await fetch(`${variables.DEFAULT_CONFIG['LAMBDA_API_URL']}/authenticate?user=${challengeParam.USERNAME}&uuid=${challengeParam.tracker}`,{
            method: 'GET',
          });
          authVerificationResponse = await verifyResponse.json();
        }      

        if (authVerificationResponse.verified === true){
          try {
            // to send the answer of the custom challenge
    
            const challengeAnswerResponse = await Auth.sendCustomChallengeAnswer(
              signinResponseUser,
              'ANY_RAND0M_VALUE'
            );
            
            currentUser = challengeAnswerResponse;
            setStatusToTrue();
            clearInterval(authIntervalPersistence);
            return(challengeAnswerResponse)
            
          } catch (err) {
            helpers.logToOutput('Challenge error: ', err);
          }
          
          clearInterval(authIntervalPersistence);
        }

      }, 1000 * 5); // 5 secs

      return signinResponseUser;
    }

  } catch (error) {
    return {
      status: variables.DEFAULT_API_RESULT.FAILURE,
      errorMessage: error
    }
  }
}

export async function respond(email: string, password?: string, newPassword?: string, userMfaCode?: string, userMfaSetupCode?: string) {
  try {
    currentUser = await Auth.signIn(email);

    return currentUser;
  } catch (error) {
    helpers.logToOutput('Sign in: error', error);
  }
}


export async function signOut() {
  if (currentUser) {
    currentUser.signOut()
    
    await Auth.signOut({ global: true });

    await Auth.Credentials.clear();

    if (AWS.config.credentials) {
      // AWS.config.credentials?.clearCachedId(); // this is the clear session
      AWS.config.credentials = new AWS.CognitoIdentityCredentials(); // this is the new instance after the clear
    } 

    localStorage.clear();
    // retrieve all cookies
    // let Cookies = document.cookie.split(';');
    // set past expiry to all cookies .... need to do this to ensure we are clearing all
    let cookies = document.cookie.split('; ');
    for (let c = 0; c < cookies.length; c++) {
      let d = window.location.hostname.split('.');
      while (d.length > 0) {
        let cookieBase = encodeURIComponent(cookies[c].split(';')[0].split('=')[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
        let p = window.location.pathname.split('/');
        document.cookie = cookieBase + '/';
        while (p.length > 0) {
          document.cookie = cookieBase + p.join('/');
          p.pop();
        }
        d.shift();
      }
    }
    
  }
}

export function getCurrentUser() {
  return currentUser
}


export async function getSession() {
  if (!currentUser) {
    currentUser = await Auth.currentAuthenticatedUser();
  }

  // await Auth.currentAuthenticatedUser();


  return new Promise(function (resolve, reject) {
    currentUser.getSession(function (err: any, session: any) {
      if (err) {
        reject(err)
      } else {
        resolve(session)
      }
    })
  }).catch((err) => {
    throw err
  })
}

export async function getAmplifyCurrentAthenticatedUser() {
  return await Auth.currentAuthenticatedUser();
}

export async function signUpUserWithEmail(email: string, setupVerifyCode?: string,  username?: string,  password?: string) {
  try {
    const response = await Auth.signUp({
      username: email,
      password: 'P@ssword123',
      attributes: {
        // email, // optional
        // phoneNumber, // optional - E.164 number convention
        // other custom attributes
      },
      autoSignIn: {
        // optional - enables auto sign in after user is confirmed
        enabled: false,
      },
    });
    return response
  } catch (error) {
    helpers.logToOutput('error signing up:', error);
    // return error
  }

}

export async function verifyCode(username: string, code: string) {
  return 
}


export async function getAttributes() {
  return 
}

export async function setAttribute(attribute: any) {
  return 
}

export async function sendCode(username: string) {
  return 
  // new Promise(function (resolve, reject) {
  //   const cognitoUser = getCognitoUser(username)

  //   if (!cognitoUser) {
  //     reject(`could not find ${username}`)
  //     return
  //   }

  //   cognitoUser.forgotPassword({
  //     onSuccess: function (res) {
  //       resolve(res)
  //     },
  //     onFailure: function (err) {
  //       reject(err)
  //     },
  //   })
  // }).catch((err) => {
  //   throw err
  // })
}

export async function forgotPassword(username: string, code?: string, newPassword?: string) {
  return new Promise(async function (resolve, reject) {
    try {
      let response;

      if(newPassword && code){
        response = await Auth.forgotPasswordSubmit(username, code, newPassword)
      } else {
        response = await Auth.forgotPassword(username);
      }

      resolve(response)

    } catch (error) {
      reject(error)
    }
    
  })
}

export async function changePassword(oldPassword: string, newPassword: string) {
  return new Promise(async function (resolve, reject) {
    try {

      const response = await Auth.changePassword(currentUser, oldPassword, newPassword);
      resolve(response);

    } catch(error){
      reject(error)
    }
  })
}
